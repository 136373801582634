import i18n from '../../i18n/i18n'

export default [

  {
    title: i18n.t('general.papers_list'),
    route: 'school-research-papers',
    icon: 'BookOpenIcon',
    required_permission: 'sola'
  },

  {
    title: i18n.t('general.menthors'),
    route: 'menthors',
    icon: 'UsersIcon',
    required_permission: 'sola'
  },
  {
    title: i18n.t('general.authors'),
    route: 'authors',
    icon: 'UsersIcon',
    required_permission: 'sola'
  },

  // {
  //   title: i18n.t('general.school'),
  //   route: 'school',
  //   icon: 'BookOpenIcon',
  //   required_permission: 'admin'
  // },

  {
    title: i18n.t('general.school_year'),
    route: 'school-year',
    icon: 'ClockIcon',
    required_permission: 'admin'
  },
  {
    title: i18n.t('general.papers_list'),
    route: 'admin-research-papers',
    icon: 'BookOpenIcon',
    required_permission: 'admin'
  },
  {
    title: i18n.t('general.authors'),
    route: 'admin-authors',
    icon: 'UsersIcon',
    required_permission: 'admin'
  },
  {
    title: i18n.t('general.menthors'),
    route: 'admin-menthors',
    icon: 'UsersIcon',
    required_permission: 'admin'
  },

  {
    title: i18n.t('general.school_list'),
    route: 'school-list',
    icon: 'InboxIcon',
    required_permission: 'admin'
  },
  {
    title: i18n.t('general.field_list'),
    route: 'field-list',
    icon: 'FlagIcon',
    required_permission: 'admin'
  },
  {
    title: i18n.t('general.faq'),
    route: 'faq',
    icon: 'HelpCircleIcon'
  }
]
